$enable-gradients: true;

// Customize some defaults
$border-radius: .4rem;

$primary: #c75c5cff;
$secondary: #f5cf87ff;

$primary-alt: #c75c5cff !important;
$secondary-alt: #f5cf87ff;

.btn-primary {
  color: white !important;
}

@import "bootstrap/scss/bootstrap"; // TODO: dark

@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.css";

@import "jquery-ui/dist/themes/base/jquery-ui.min.css";
@import "leaflet/dist/leaflet.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.bi {
  fill: currentcolor;
}

.ui-datepicker-calendar {
  display: none;
}
